import React, { useState } from "react";
import { toast } from 'react-toastify';

import axios from "axios";
import { TbBrandFiverr, TbBrandUpwork } from "react-icons/tb";

const Contact = ({ darkMode }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const [response, setResponse] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('darkMode: ', darkMode);
    const { name, email, phone, subject, message } = formData
    const toastSetting = {
      theme: darkMode ? "dark" : "light",
    };
    const validateEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email); // Validate email format
    };
    const validatePhoneNumber = (phoneNumber) => {
      const regex = /^\+[1-9]\d{0,2}[- \()]*\d{4,14}$/;

      // Trim whitespace and test
      phoneNumber = phoneNumber.trim();

      if (!regex.test(phoneNumber)) {
        // Check if country code is missing
        if (!phoneNumber.startsWith('+')) {
          toast.error("Country code is missing. Please include the country code.");
          return false;
        } else {
          toast.error("Invalid phone number. Please enter a valid phone number.");
          return false;
        }
      }
      return true; // Phone number is valid
    }

    if (!name || !email || !phone || !subject || !message) {
      return toast.error("All fields are required!", toastSetting);
    } else if (!validatePhoneNumber(phone)) {
      return
    } else if (!validateEmail(email)) {
      return toast.error("Enter valid email", toastSetting);
    }

    try {
      const res = await axios.post("https://portfolio-api.abhishek-tank.com/send-mail", formData);
      return toast.success(res.data, toastSetting);
    } catch (error) {
      return toast.error("Error sending message", toastSetting);
    }

  };

  return (
    <div
      className="rn-contact-area rn-section-gap section-separator"
      id="contacts"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center">
              <span className="subtitle">Contact</span>
              <h2 className="title">Contact With Me</h2>
            </div>
          </div>
        </div>

        <div className="row mt--50 mt_md--40 mt_sm--40 mt-contact-sm contact-extra">
          {/* <div className="col-lg-5"> */}
          {/* <div className="contact-about-area">
              <div className="thumbnail">
                <img
                  src="assets/images/contact/contact1.png"
                  alt="contact-img"
                />
              </div> */}
          {/* <div className="title-area">
                <h4 className="title">Nevine Acotanza</h4>
                <span>Chief Operating Officer</span>
              </div>
              <div className="description">
                <p>
                  I am available for freelance work. Connect with me via and
                  call in to my account.
                </p>
                <span className="phone">
                  Phone: <a href="tel:01941043264">+01234567890</a>
                </span>
                <span className="mail">
                  Email:
                  <a href="mailto:admin@example.com">admin@example.com</a>
                </span>
              </div> */}

          {/* </div> */}
          {/* </div> */}
          <div data-aos-delay="600" className="col-lg-7 contact-input">
            <div className="contact-form-wrapper">
              <div className="introduce">
                <form
                  className="rnt-contact-form rwt-dynamic-form row"
                  id="contact-form"
                // onSubmit={handleSubmit}
                >
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label htmlFor="contact-name">Your Name</label>
                      <input
                        className="form-control form-control-lg"
                        name="name"
                        id="contact-name"
                        type="text"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label htmlFor="contact-phone">Phone Number</label>
                      <input
                        className="form-control"
                        name="phone"
                        id="contact-phone"
                        type="text"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="form-group">
                      <label htmlFor="contact-email">Email</label>
                      <input
                        className="form-control form-control-sm"
                        id="contact-email"
                        name="email"
                        type="email"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="form-group">
                      <label htmlFor="subject">Subject</label>
                      <input
                        className="form-control form-control-sm"
                        id="subject"
                        name="subject"
                        type="text"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="form-group">
                      <label htmlFor="contact-message">Your Message</label>
                      <textarea
                        name="message"
                        id="contact-message"
                        cols="30"
                        rows="10"
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <button
                      name="submit"
                      // type="submit"
                      id="submit"
                      className="rn-btn w-100"
                      onClick={handleSubmit}
                    >
                      <span>SEND MESSAGE</span>
                      <i data-feather="arrow-right"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {response && <p>{response}</p>}
      </div>
    </div>
  );
};

export default Contact;
