import React from "react";

const GigsData = [
  {
    id: 1,
    imageUrl: "assets/images/gigs/4.png",
    category: "Development",
    // likes: 600,
    rating: 5,
    count: 38,
    title: "Develop nodejs rest apis with expressjs, postman and mongodb",
    link: "https://www.fiverr.com/abhishektank129/create-rest-api-with-node-js-express-js-mongodb-postman?context_referrer=seller_page&ref_ctx_id=27b18f0602d24737931563b1ce733942&pckg_id=1&pos=1&imp_id=9ea0df6f-a2e4-4a7e-93b2-a7fa01790c61",
  },
  {
    id: 2,
    imageUrl: "assets/images/gigs/6.png",
    category: "Development",
    // likes: 750,
    rating: 5,
    count: 3,
    title: "Develop a rest API in python using flask or django",
    link: "https://www.fiverr.com/abhishektank129/your-nodejs-developer-for-building-apis-and-backends?context_referrer=seller_page&ref_ctx_id=27b18f0602d24737931563b1ce733942&pckg_id=1&pos=2&imp_id=b3efe593-ace0-4efc-85ff-f101cacd848f",
  },
  {
    id: 3,
    imageUrl: "assets/images/gigs/1.png",
    category: "Development",
    // likes: 630,
    rating: 5,
    count: 1,
    title: "Full stack developer python nodejs PHP bootstrap",
    link: "https://www.fiverr.com/abhishektank129/be-your-full-stack-developer-python-nodejs-php-bootstrap?context_referrer=seller_page&ref_ctx_id=27b18f0602d24737931563b1ce733942&pckg_id=1&pos=3&imp_id=0d9de91a-3fea-493d-9e8d-5b54f961f98a",
  },
  {
    id: 4,
    imageUrl: "assets/images/gigs/3.png",
    category: "Development",
    // likes: 360,
    rating: 5,
    count: 1,
    title: "Create or fix ajax, jquery, PHP, laravel, HTML, and CSS bugs",
    link: "https://www.fiverr.com/abhishektank129/do-html-css-javascript-nodejs-development?context_referrer=seller_page&ref_ctx_id=27b18f0602d24737931563b1ce733942&pckg_id=1&pos=4&imp_id=e0c9cfd9-0797-4398-a858-e9c2ceb9de4e",
  },
  {
    id: 5,
    imageUrl: "assets/images/gigs/2.png",
    category: "Backend",
    // likes: 200,
    rating: 0,
    count: 0,
    title: "Professional backend web developer",
    link: "https://www.fiverr.com/abhishektank129/be-your-professional-backend-web-developer?context_referrer=seller_page&ref_ctx_id=27b18f0602d24737931563b1ce733942&pckg_id=1&pos=5&imp_id=8ec83f52-9bc4-4ad4-b5a0-e2b9eae3d248",
  },
  {
    id: 6,
    imageUrl: "assets/images/gigs/5.png",
    category: "prompt engineer",
    // likes: 690,
    rating: 0,
    count: 0,
    title: "Prompt engineer for openai chatgpt, bard",
    link: "https://www.fiverr.com/abhishektank129/your-prompt-engineer-for-openai-chatgpt-bard?context_referrer=seller_page&ref_ctx_id=27b18f0602d24737931563b1ce733942&pckg_id=1&pos=6&imp_id=f83c347f-bce4-442e-9993-163ffd6e99b7",
  },
];

const GigItem = ({
  id,
  imageUrl,
  category,
  likes,
  title,
  link,
  rating,
  count,
}) => {
  const handleClick = (event) => {
    event.preventDefault(); // Prevents the default action (navigating to the link)
    window.open(link, "_blank"); // Opens the link in a new tab
  };

  return (
    <div
      className="col-lg-6 col-xl-4 col-md-6 col-12 mt--50 mt_md--30 mt_sm--30"
      data-aos-delay="100"
      data-aos-once="true"
    >
      <div className="rn-portfolio">
        <div className="inner">
          <div className="thumbnail">
            <img src={imageUrl} alt="Portfolio" />
          </div>
          <div className="content">
            <div className="category-info">
              <div className="category-list">
                <a href={link} onClick={(e) => e.preventDefault()}>
                  {category}
                </a>
              </div>
              <div className="meta">
                {rating != 0 && (
                  <span
                    style={{
                      display: "flex",
                      columnGap: "5px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {/* <i className="feather-heart"></i>
                  {likes}  */}
                    <img
                      src="assets/images/icons/rating.png"
                      alt="rating-image"
                      style={{
                        width: "14px",
                        height: "14px",
                        objectFit: "contain",
                      }}
                    />
                    <span style={{ fontSize: "15px", fontWeight: "800" }}>
                      {rating.toFixed(1)}({count})
                    </span>
                  </span>
                )}
              </div>
            </div>
            <h4 className="title">
              <a href={link} target="_blank" rel="noopener noreferrer">
                {title} <i className="feather-arrow-up-right"></i>
              </a>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

const Gigs = () => {
  return (
    <div
      className="rn-portfolio-area rn-section-gap section-separator"
      id="gigs"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center">
              <span className="subtitle">
                Visit my Gigs and keep your feedback
              </span>
              <h2 className="title">Gigs</h2>
            </div>
          </div>
        </div>

        <div className="row row--25 mt--10 mt_md--10 mt_sm--10">
          {GigsData.map((item) => (
            <GigItem
              key={item.id}
              id={item.id}
              imageUrl={item.imageUrl}
              category={item.category}
              likes={item.likes}
              title={item.title}
              link={item.link}
              rating={item.rating}
              count={item.count}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Gigs;
