import React, { useEffect } from "react";
import feather from "feather-icons";
import Slider from "react-slick";

const portfolioData = [
  {
    id: 1,
    imageUrl: [
      "assets/images/portfolio/portfolio3.png",
      "assets/images/portfolio/portfolio3-1.png",
      "assets/images/portfolio/portfolio3-2.png",
      "assets/images/portfolio/portfolio3-3.png",
      "assets/images/portfolio/portfolio3-4.png",
      "assets/images/portfolio/portfolio3-5.png",
      "assets/images/portfolio/portfolio3-6.png",
    ],
    category: "Mobile App",
    // likes: 600,
    title: "Fresh Clean",
    description:
      "Fresh & Clean app connects you with trusted cleaners for a sparkling home. Book in a few taps, reclaim your time, and enjoy a stress-free clean.",
    link: "https://www.fiverr.com/users/abhishektank129/portfolio/NjVmNjkyN2NjODIxMTMwMDAxYTUwZTc4",
  },
  {
    id: 2,
    imageUrl: [
      "assets/images/portfolio/portfolio1.png",
      "assets/images/portfolio/portfolio1-1.png",
      "assets/images/portfolio/portfolio1-2.png",
      "assets/images/portfolio/portfolio1-3.png",
      "assets/images/portfolio/portfolio1-4.png",
      "assets/images/portfolio/portfolio1-5.png",
    ],
    category: "Web App",
    // likes: 600,
    title: "Uspend",
    description:
      "Introducing Uspend: Your intuitive expense management solution. Ditch the pen and paper for our user-friendly software. Input expenses effortlessly, stored securely in the database. View your expenses in a convenient list and track your spending with insightful graphs. Uspend makes daily expense management a breeze, ensuring you never miss a beat in your finances.",
    link: "https://www.fiverr.com/users/abhishektank129/portfolio/NjU2Nzc4MTZhYWViMWIwMDAxZTI3YzI4",
  },
  
  {
    id: 3,
    imageUrl: [
      "assets/images/portfolio/portfolio4.png",
      "assets/images/portfolio/portfolio4-1.png",
      "assets/images/portfolio/portfolio4-2.png",
      "assets/images/portfolio/portfolio4-3.png",
      "assets/images/portfolio/portfolio4-4.png",
      "assets/images/portfolio/portfolio4-5.png",
    ],
    category: "Mobile App",
    // likes: 750,

    title: "Asstrapp",
    description:
      "Asstrapp leverages AI to transform your phone into a high-precision speed measurement tool for athletes. Ditch expensive photocells & experience effortless setup, accurate data, & endless test options.",
    link: "https://www.fiverr.com/users/abhishektank129/portfolio/NjVmNjkyY2VkODYyMzEwMDAxNzRhY2Vh",
  },
  {
    id: 4,
    imageUrl: [
      "assets/images/portfolio/portfolio5.png",
      "assets/images/portfolio/portfolio5-2.png",
      "assets/images/portfolio/portfolio5-3.png",
      "assets/images/portfolio/portfolio5-4.png",
    ],
    category: "Mobile App",
    // likes: 750,

    title: "Take n Go",
    description:
      "Take n Go redefines the shopping experience with QR code scanning for seamless purchases. Simply scan, shop, and view your virtual cart within the app, ensuring a convenient and efficient way to shop on-the-go. Say goodbye to long checkout lines and hello to hassle-free shopping with Take n Go.",
    link: "https://www.fiverr.com/users/abhishektank129/portfolio/NjVmNmZlMDVkODYyMzEwMDAxNzRkNzhl",
  },
  {
    id: 5,
    imageUrl: [
      "assets/images/portfolio/portfolio6.png",
      "assets/images/portfolio/portfolio6-2.png",
      "assets/images/portfolio/portfolio6-3.png",
      "assets/images/portfolio/portfolio6-4.png",
      "assets/images/portfolio/portfolio6-5.png",
      "assets/images/portfolio/portfolio6-6.png",
      "assets/images/portfolio/portfolio6-7.png",
    ],
    category: "Mobile App",
    // likes: 750,

    title: "Run",
    description:
      "Introducing RUN, the ultimate fitness companion that revolutionizes your workout routine. Track your total steps, unlock rewards, and access curated workout videos seamlessly within the app. Visualize your progress with intuitive graphs, motivating you to achieve your fitness goals. With RUN, every step counts towards a healthier, more active lifestyle. Join the fitness revolution today!",
    link: "https://www.fiverr.com/users/abhishektank129/portfolio/NjVmNmZmZWFkNDk4NzAwMDAxZTNmZWIw",
  },
  {
    id: 6,
    imageUrl: [
      "assets/images/portfolio/portfolio2.png",
      "assets/images/portfolio/portfolio2-2.png",
      "assets/images/portfolio/portfolio2-3.png",
      "assets/images/portfolio/portfolio2-4.png",
      "assets/images/portfolio/portfolio2-5.png",
    ],
    category: "Web App",
    // likes: 750,

    title: "T-Talkiee : Talk To Table",
    description:
      "Introducing our next-gen food ordering system: a seamless, AI-driven solution replacing outdated pen and paper methods. Our intuitive software features a virtual assistant to take orders, streamlining the process from customer to kitchen. Displayed order lists ensure accuracy, while automated commands ensure prompt service. Revolutionizing restaurant orders, our goal is to minimize wait times and maximize efficiency.",
    link: "https://t-talkiee.abhishek-tank.com/",
  },
];

const MySlider = ({ imgs }) => {
  const style = { color: "white", fontSize: "1.5em" };

  const settings = {
    dots: imgs.length > 1,
    infinite: imgs.length > 1,
    slidesToShow: 1,
    slidesToScroll: imgs.length > 1 ? 1 : 0,
    autoplay: imgs.length > 1,
    autoplaySpeed: 1000,
    prevArrow: <></>,
    nextArrow: <></>,
  };

  return (
    <Slider {...settings}>
      {/* Iterate through the `imgs` array and render each image */}
      {imgs.map((path, index) => (
        <img key={index} className="w-100" src={path} alt="slide" />
      ))}
    </Slider>
  );
};

const PortfolioModal = () => {
  useEffect(() => {
    feather.replace();
  }, []);
  return (
    <>
      {portfolioData.map((item) => (
        <div
          key={item.id}
          className="modal fade"
          id={`exampleModalCenter${item.id}`}
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">
                    <i data-feather="x"></i>
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="portfolio-popup-thumbnail">
                      <div className="image">
                        <MySlider imgs={item.imageUrl} />
                        {/* <img
                          className="w-100"
                          src={item.imageUrl}
                          alt="slide"
                        /> */}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="text-content">
                      <h3>
                        <span>{item.category}</span> {item.title}
                      </h3>
                      <p className="mb--30">{item.description}</p>
                      {/* <p>{item.para2}</p> */}
                      <div className="button-group mt--20">
                        {/* <a href="#" className="rn-btn thumbs-icon">
                          <span>LIKE THIS</span>
                          <i data-feather="thumbs-up"></i>
                        </a> */}
                        <a href={item.link} className="rn-btn">
                          <span>VIEW PROJECT</span>
                          <i data-feather="chevron-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default PortfolioModal;
