import React from "react";
import { TbBrandFiverr } from "react-icons/tb";

import { TbBrandUpwork } from "react-icons/tb";

const Home = () => {
  return (
    <div id="home" class="rn-slider-area">
      <div class="slide slider-style-1">
        <div class="container">
          <div class="row row--30 align-items-center">
            <div class="order-2 order-lg-1 col-lg-7 mt_md--50 mt_sm--50 mt_lg--30">
              <div class="content">
                <div class="inner">
                  <h1 class="title">
                    Hi, I’m <span>Abhishek Tank</span>
                    <br />
                    <span class="header-caption" id="page-top">
                      {/* <!-- type headline start--> */}
                      <span
                        class="cd-headline clip is-full-width"
                        style={{ display: "flex" }}
                      >
                        <span>a&nbsp;</span>
                        {/* <!-- ROTATING TEXT --> */}
                        <span
                          class="cd-words-wrapper"
                          // style={{ border: "2px solid black" }}
                        >
                          <b class="is-visible">Software Engineer.</b>
                          <b class="is-hidden">Freelancer.</b>
                          {/* <b class="is-hidden">Developer.</b> */}
                        </span>
                      </span>
                      {/* <!-- type headline end --> */}
                    </span>
                  </h1>

                  <div>
                    <p class="description">
                    Believe in the power of diverse perspectives and seeing the world through a thousand eyes. Every interaction is a learning opportunity, and personal growth is a continuous journey.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12">
                    <div class="social-share-inner-left">
                      <span class="title">find with me</span>
                      <ul class="social-share d-flex liststyle">
                        <li class="linkedin">
                          <a href="https://www.linkedin.com/in/abhishek-tank/">
                            <i data-feather="linkedin"></i>
                          </a>
                        </li>
                        <li className="upwork">
                          <a href="https://www.upwork.com/freelancers/~01eb37ab7b6ad71d47#" className="upwork-link">
                            <TbBrandUpwork size={"60px"} />
                          </a>
                        </li>
                        <li className="fiverr">
                          <a href="https://www.fiverr.com/abhishektank129" className="fiverr-link">
                            <TbBrandFiverr size={"60px"} />
                          </a>
                        </li>

                        <li class="instagram">
                          <a href="https://www.instagram.com/abhishek___tank/">
                            <i data-feather="instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <div class="col-lg-6 col-xl-6 col-md-6 col-sm-6 col-12 mt_mobile--30">
                    <div class="skill-share-inner">
                      <span class="title">best skill on</span>
                      <ul class="skill-share d-flex liststyle">
                        <li>
                          <img
                            src="assets/images/icons/icons-01.png"
                            alt="Icons Images"
                          />
                        </li>
                        <li>
                          <img
                            src="assets/images/icons/icons-02.png"
                            alt="Icons Images"
                          />
                        </li>
                        <li>
                          <img
                            src="assets/images/icons/icons-03.png"
                            alt="Icons Images"
                          />
                        </li>
                      </ul>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>

            <div class="order-1 order-lg-2 col-lg-5 home-img-extra">
              <div className="about-img">
                <img
                  src="assets/images/slider/banner-01.png"
                  alt="Personal Portfolio Images"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
