import React from "react";
import Header from "./Header";
import MainContent from "./MainContent";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "./Footer";
import { useState } from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Main = () => {
  const [darkMode, setDarkMode] = useState(false);
  // console.log(darkMode);
  return (
    <div>
      <Header setDarkMode={setDarkMode} darkMode={darkMode} />
      <MainContent darkMode={darkMode} />
      <Footer />
      <ToastContainer
      />
    </div>
  );
};

export default Main;
