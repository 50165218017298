import React from "react";

const Services = () => {
  return (
    <div class="rn-service-area rn-section-gap section-separator" id="features">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div
              class="section-title text-left"
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-delay="100"
              data-aos-once="true"
            >
              <span class="subtitle">Features</span>
              <h2 class="title">What I Do</h2>
            </div>
          </div>
        </div>
        <div class="row row--25 mt_md--10 mt_sm--10">
          {/* <!-- Start Single Service --> */}
          <div
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay="100"
            data-aos-once="true"
            class="col-lg-6 col-xl-4 col-md-6 col-sm-12 col-12 mt--50 mt_md--30 mt_sm--30"
          >
            <div class="rn-service">
              <div class="inner">
                <div class="icon">
                  <i data-feather="code"></i>
                </div>
                <div class="content">
                  <h4 class="title">
                    <a href="#">Custom Software Development</a>
                  </h4>
                  <p class="description">
                    Tailored solutions engineered to meet your unique business requirements, ensuring scalability, efficiency, and seamless integration with your existing systems.
                  </p>
                  {/* <a class="read-more-button" href="#">
                    <i class="feather-arrow-right"></i>
                  </a> */}
                </div>
              </div>
              <a class="over-link" href="#"></a>
            </div>
          </div>
          {/* <!-- End SIngle Service --> */}
          {/* <!-- Start Single Service --> */}
          <div
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay="300"
            data-aos-once="true"
            class="col-lg-6 col-xl-4 col-md-6 col-sm-12 col-12 mt--50 mt_md--30 mt_sm--30"
          >
            <div class="rn-service">
              <div class="inner">
                <div class="icon">
                  <i data-feather="globe"></i>
                </div>
                <div class="content">
                  <h4 class="title">
                    <a href="#">Web Application Development</a>
                  </h4>
                  <p class="description">
                    Innovative web solutions crafted to captivate users, enhance engagement, and drive business growth through intuitive design and cutting-edge technology.
                  </p>
                  {/* <a class="read-more-button" href="#">
                    <i class="feather-arrow-right"></i>
                  </a> */}
                </div>
              </div>
              <a class="over-link" href="#"></a>
            </div>
          </div>
          {/* <!-- End SIngle Service --> */}
          {/* <!-- Start Single Service --> */}
          <div
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay="500"
            data-aos-once="true"
            class="col-lg-6 col-xl-4 col-md-6 col-sm-12 col-12 mt--50 mt_md--30 mt_sm--30"
          >
            <div class="rn-service">
              <div class="inner">
                <div class="icon">
                  <i data-feather="smartphone"></i>
                </div>
                <div class="content">
                  <h4 class="title">
                    <a href="#">Mobile App <br />Development</a>
                  </h4>
                  <p class="description">
                    Dynamic mobile applications designed to elevate user experiences, expand market reach, and empower businesses to thrive in the ever-evolving digital landscape.
                  </p>
                  {/* <a class="read-more-button" href="#">
                    <i class="feather-arrow-right"></i>
                  </a> */}
                </div>
              </div>
              <a class="over-link" href="#"></a>
            </div>
          </div>
          {/* <!-- End SIngle Service --> */}
          {/* <!-- Start Single Service --> */}
          <div
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay="100"
            data-aos-once="true"
            class="col-lg-6 col-xl-4 col-md-6 col-sm-12 col-12 mt--50 mt_md--30 mt_sm--30"
          >
            <div class="rn-service">
              <div class="inner">
                <div class="icon">
                  <i data-feather="shopping-bag"></i>
                </div>
                <div class="content">
                  <h4 class="title">
                    <a href="#">WordPress & Shopify</a>
                  </h4>
                  <p class="description">
                    Expert solutions tailored for WordPress and Shopify platforms, offering personalized themes, plugins, and e-commerce functionality to create stunning websites and online stores that resonate with your audience and drive conversions.
                  </p>
                  {/* <a class="read-more-button" href="#">
                    <i class="feather-arrow-right"></i>
                  </a> */}
                </div>
              </div>
              <a class="over-link" href="#"></a>
            </div>
          </div>
          {/* <!-- End SIngle Service --> */}
          {/* <!-- Start Single Service --> */}
          <div
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay="300"
            data-aos-once="true"
            class="col-lg-6 col-xl-4 col-md-6 col-sm-12 col-12 mt--50 mt_md--30 mt_sm--30"
          >
            <div class="rn-service">
              <div class="inner">
                <div class="icon">
                  <i data-feather="settings"></i>
                </div>
                <div class="content">
                  <h4 class="title">
                    <a href="#">Support and Maintenance</a>
                  </h4>
                  <p class="description">
                    Reliable ongoing support services to safeguard your digital assets, ensure optimal performance, and address any technical issues promptly, allowing you to focus on your core business objectives.
                  </p>
                  {/* <a class="read-more-button" href="#">
                    <i class="feather-arrow-right"></i>
                  </a> */}
                </div>
              </div>
              <a class="over-link" href="#"></a>
            </div>
          </div>
          {/* <!-- End SIngle Service --> */}
          {/* <!-- Start Single Service --> */}
          <div
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay="500"
            data-aos-once="true"
            class="col-lg-6 col-xl-4 col-md-6 col-sm-12 col-12 mt--50 mt_md--30 mt_sm--30"
          >
            <div class="rn-service">
              <div class="inner">
                <div class="icon">
                  <i data-feather="git-merge"></i>
                </div>
                <div class="content">
                  <h4 class="title">
                    <a href="#">DevOps</a>
                  </h4>
                  <p class="description">
                    Streamlined development and operations processes leveraging DevOps practices, automation, and continuous integration/ continuous deployment (CI/CD) pipelines to accelerate project delivery and enhance collaboration.
                  </p>
                  {/* <a class="read-more-button" href="#">
                    <i class="feather-arrow-right"></i>
                  </a> */}
                </div>
              </div>
              <a class="over-link" href="#"></a>
            </div>
          </div>
          {/* <!-- End SIngle Service --> */}
        </div>
      </div>
    </div>
  );
};

export default Services;
