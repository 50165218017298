import React from "react";
const Footer = () => {
  return (
    <div class="rn-footer-area rn-section-gap section-separator">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="footer-area text-center">
              <div class="logo">
                <a href="/">
                  <img
                    src="assets/images/logo/logo-vertical-dark.png"
                    alt="logo"
                  />
                </a>
              </div>
              <p class="description mt--30">
                © 2024. All rights reserved by Abhishek Tank
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
