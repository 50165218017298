import Main from "./Main";

import '@fortawesome/fontawesome-free/css/all.css';

function App() {
  return (
    <Main />
  );
}

export default App;
