import React from "react";

const portfolioData = [
  {
    id: 1,
    imageUrl: "assets/images/portfolio/portfolio3.png",
    category: "Mobile App",
    // likes: 750,
    title: "Fresh Clean",
  },
  {
    id: 2,
    imageUrl: "assets/images/portfolio/portfolio1.png",
    category: "Web App",
    // likes: 600,
    title: "Uspend",
  }, 
  {
    id: 3,
    imageUrl: "assets/images/portfolio/portfolio4.png",
    category: "Mobile App",
    // likes: 750,
    title: "Asstrapp",
  },
  {
    id: 4,
    imageUrl: "assets/images/portfolio/portfolio5.png",
    category: "Mobile App",
    // likes: 750,
    title: "Take n Go",
  },
  {
    id: 5,
    imageUrl: "assets/images/portfolio/portfolio6.png",
    category: "Mobile App",
    // likes: 750,
    title: "Run",
  },
  {
    id: 6,
    imageUrl: "assets/images/portfolio/portfolio2.png",
    category: "Web App",
    // likes: 750,
    title: "T-Talkiee : Talk To Table",
  },
];

const PortfolioItem = ({ id, imageUrl, category, likes, title }) => {
  return (
    <div
      className="col-lg-6 col-xl-4 col-md-6 col-12 mt--50 mt_md--30 mt_sm--30"
      data-aos-delay="100"
      data-aos-once="true"
    >
      <div
        className="rn-portfolio"
        data-bs-toggle="modal"
        data-bs-target={`#exampleModalCenter${id}`}
      >
        <div className="inner">
          <div className="thumbnail">
            <a href="javascript:void(0)">
              <img src={imageUrl} alt="Portfolio" />
            </a>
          </div>
          <div className="content">
            <div className="category-info">
              <div className="category-list">
                <a href="javascript:void(0)">{category}</a>
              </div>
              {/* <div className="meta">
                <span>
                  <a href="javascript:void(0)">
                    <i className="feather-heart"></i>
                  </a>
                  {likes}
                </span>
              </div> */}
            </div>
            <h4 className="title">
              <a href="javascript:void(0)">
                {title} <i class="feather-arrow-up-right"></i>
              </a>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

const Portfolio = () => {
  return (
    <div
      className="rn-portfolio-area rn-section-gap section-separator"
      id="portfolio"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center">
              <span className="subtitle">
                Visit Portfolio and keep your feedback
              </span>
              <h2 className="title">  Portfolio</h2>
            </div>
          </div>
        </div>

        <div className="row row--25 mt--10 mt_md--10 mt_sm--10">
          {portfolioData.map((item) => (
            <PortfolioItem
              key={item.id}
              id={item.id}
              imageUrl={item.imageUrl}
              category={item.category}
              likes={item.likes}
              title={item.title}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
