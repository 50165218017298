import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { FaReact, FaNode } from "react-icons/fa";
import { SiExpress } from "react-icons/si";
import { IoLogoAndroid } from "react-icons/io";
import { SiFlutter } from "react-icons/si";

const MySlider = ({ slidesToShow, isDark }) => {
  const style = { color: "white", fontSize: "1.5em" };
  // const isWhiteVersion = document.body.classList.contains("white-version");

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    prevArrow: <></>,
    nextArrow: <></>,
  };

  return (
    <Slider {...settings}>
      {/* <div className="skills-img"> */}
      {/* Backend */}
      {!isDark ? (
        <img src="assets/images/skills/node2.png" className="img-skills" />
      ) : (
        <img src="assets/images/skills/node1.png" className="img-skills" />
      )}
      <img src="assets/images/skills/express.png" className="img-skills extra" />
      <img src="assets/images/skills/nest.png" className="img-skills extra" />
      <img src="assets/images/skills/python.png" className="img-skills" />
      <img src="assets/images/skills/flask.png" className="img-skills extra" />
      <img src="assets/images/skills/django.png" className="img-skills extra" />

      {/* Frontend */}
      <img src="assets/images/skills/js.png" className="img-skills" />
      <img src="assets/images/skills/react.png" className="img-skills" />
      <img src="assets/images/skills/next.png" className="img-skills extra" />
      <img src="assets/images/skills/redux.png" className="img-skills" />
      <img src="assets/images/skills/bootstrape.png" className="img-skills" />
      <img src="assets/images/skills/ejs.png" className="img-skills" />

      {/* Mobile */}
      <img src="assets/images/skills/android.png" className="img-skills" />
      <img src="assets/images/skills/flutter.png" className="img-skills" />
      <img src="assets/images/skills/ios.png" className="img-skills extra" />
      <img src="assets/images/skills/swift.png" className="img-skills" />

      {/* Database */}
      <img src="assets/images/skills/mongodb.png" className="img-skills" />
      <img src="assets/images/skills/postgrey.png" className="img-skills" />
      <img src="assets/images/skills/sql.png" className="img-skills" />

      {/* Web Services */}
      <img src="assets/images/skills/firebase.png" className="img-skills" />
      <img src="assets/images/skills/socket.png" className="img-skills extra" />

      {/* Testing */}
      <img src="assets/images/skills/postman.png" className="img-skills" />

      {/* CMS */}
      <img src="assets/images/skills/shopify.png" className="img-skills" />
      <img src="assets/images/skills/wordpress.png" className="img-skills" />
      {/* </div> */}
    </Slider>
  );
};

const Skills = ({ darkMode }) => {
  const [slidesToShow, setSlidesToShow] = useState(5);
  const [isWhiteVersion, setIsWhiteVersion] = useState(
    document.body.classList.contains("white-version")
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setSlidesToShow(5);
      } else if (window.innerWidth >= 768) {
        setSlidesToShow(4);
      } else if (window.innerWidth >= 480) {
        setSlidesToShow(3);
      } else {
        setSlidesToShow(2);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className="rn-testimonial-area rn-section-gap section-separator"
      id="skills"
    >
      <div className="coverer">
        <div className="row">
          <div className="col-lg-12">
            <div
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-delay="100"
              data-aos-once="true"
              className="section-title text-center"
            >
              <h2 className="title">Tech Stack</h2>
            </div>
          </div>
        </div>
        <div
          className="row"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="300"
          data-aos-once="true"
        >
          <div className="col-lg-12">
            <div
              className="testimonial-activation testimonial-pb mb--30 skills"
              style={{ paddingTop: "50px" }}
            >
              <MySlider slidesToShow={slidesToShow} isDark={darkMode} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
