import React, { useEffect, useState } from "react";
import feather from "feather-icons";
import Home from "./Home";
import Services from "./Services";
import Portfolio from "./Portfolio";
import Resume from "./Resume";
import Testimonia from "./Testimonia";
import Client from "./Client";
import Price from "./Price";
import Blog from "./Blog";
import Contact from "./Contact";
import PortfolioModal from "./PortfolioModal";
import BlogModal from "./BlogModal";
// import DemoRightModal from "./DemoRightModal";
import Gigs from "./Gigs";
import Skills from "./Skills";

const MainContent = ({ darkMode }) => {
  const [isVisible, setIsVisible] = useState(false);

  // useEffect(() => {
  //   feather.replace();
  // }, []);

  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    // setTimeout(() => {
    //   window.location.href = "/";
    // }, 500);
  };

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  window.addEventListener("scroll", handleScroll);

  return (
    <main class="main-page-wrapper">
      <Home />
      <Services />
      <Portfolio />
      <Skills darkMode={darkMode} />
      {/* <Resume /> */}
      <Testimonia />
      <Gigs />
      {/* <Client /> */}
      {/* <Price /> */}
      {/* <Blog /> */}
      <Contact darkMode={darkMode} />
      <PortfolioModal />
      {/* <BlogModal /> */}

      {/* <!-- Back to  top Start --> */}
      <div className={`backto-top ${isVisible ? "visible" : ""}`}>
        <div onClick={handleScrollTop}>
          <i data-feather="arrow-up"></i>
        </div>
      </div>
      {/* <!-- Back to top end --> */}

      {/* <!-- Start Right Demo  --> */}
      {/* <div class="rn-right-demo">
        <button class="demo-button">
          <span class="text">Demos</span>
        </button>
      </div> */}
      {/* <!-- End Right Demo  --> */}

      {/* <DemoRightModal /> */}
    </main>
  );
};

export default MainContent;
