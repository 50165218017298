import React, { useEffect } from "react";
import Slider from "react-slick";
import feather from "feather-icons";

const testimonialData = [
  {
    id: 1,
    imageUrl: "assets/images/testimonial/abimanju.png",
    title: "@abimanju",
    rating: 5,
    description: "Working with Abhishek Tank was a fantastic experience! He EXCEEDED expectations with his delivery, and his quick responsiveness made collaboration effortless. Highly recommend! 👍",
  },
  {
    id: 2,
    imageUrl: "assets/images/testimonial/dafina7400.png",
    title: "@dafina7400",
    rating: 5,
    description: "He was so professional and attentive to details with clear accent. Very polite as well.",
  },
  {
    id: 3,
    imageUrl: "assets/images/testimonial/ryanblomberg.png",
    title: "@ryanblomberg",
    rating: 5,
    description: "Great work, would recommend Abhishek to anyone!",
  },
  {
    id: 4,
    imageUrl: "assets/images/testimonial/ishanvimohan.png",
    title: "@ishanvimohan",
    rating: 5,
    description: "Great help! Highly recommend.",
  },
  {
    id: 5,
    imageUrl: "assets/images/testimonial/nebshi.png",
    title: "@nebshi",
    rating: 5,
    description: "Excellent work and cooperation. I would definitively recommend to all people that needs help with programming and tests.",
  },
  {
    id: 6,
    imageUrl: "assets/images/testimonial/etnies44.png",
    title: "@etnies44",
    rating: 5,
    description: "he did a great job with our API. he has assisted until the solution was up and running, even after some weeks that we needed to change hardware. he is trustful and makes a good job. thank you for the work!",
  },
  {
    id: 7,
    imageUrl: "assets/images/testimonial/trademasterlive.png",
    title: "@trademasterlive",
    rating: 5,
    description: "Good developer.",
  },
  {
    id: 8,
    imageUrl: "assets/images/testimonial/saralshopping.png",
    title: "@saralshopping",
    rating: 5,
    description: "Abhishek's work was outstanding! The documentation was top-notch, attention to detail spot-on, and the overall professionalism shone through. He was able to build a fully functional task in short period of time. Always very professional and communicative. Attention to detail was noteworthy. Overall, we have a solid experience with him! Would strongly recommend for any professional task development or large scale project needs. 👍",
  },
  {
    id: 9,
    imageUrl: "assets/images/testimonial/martineachstore.png",
    title: "@martineachstore",
    rating: 5,
    description: "I am writing to express my strongest recommendation for Abhishek. I have had the pleasure of working with him for my gig. During this time, I have been consistently impressed by his: Technical knowledge: Abhishek possesses a deep understanding and is always eager to learn and expand his skillset. He is able to quickly grasp complex concepts and apply them effectively in his work.",
  },
  {
    id: 10,
    imageUrl: "assets/images/testimonial/tomski198.png",
    title: "@tomski198",
    rating: 5,
    description: "Tank delivered exceptional service with remarkable speed and a friendly demeanor. His expertise was evident as he skillfully guided me through the most efficient ways to achieve my objectives. I was thoroughly impressed by his knowledge and the ease with which he helped streamline my tasks.",
  },
  {
    id: 11,
    imageUrl: "assets/images/testimonial/senor_kyle.png",
    title: "@senor_kyle",
    rating: 5,
    description: "He's really good, and was able to help figure out this issue in a very short amount of time. A pleasure doing business with him .",
  },
  {
    id: 12,
    imageUrl: "assets/images/testimonial/bythomasnguyen.png",
    title: "@bythomasnguyen",
    rating: 5,
    description: "I recently had the pleasure of experiencing exceptional service from abhishektank129, and I can't express how satisfied I am with his assistance. abhishektank129's quick service and his willingness to go the extra mile to help were truly impressive.",
  },
  {
    id:13,
    imageUrl: "assets/images/testimonial/rmcgirt95.png",
    title: "@rmcgirt95",
    rating: 5,
    description: "I had the pleasure of working with Abhishektank129, who built a fantastic full-stack website for me using Node.js and Express.js. Their expertise and professionalism were truly exceptional throughout the entire project. Abhishektank129's commitment to delivering a top-notch product was evident from the start. They demonstrated an impressive command of Node.js and Express.js, crafting a robust backend that handled data seamlessly and efficiently. Their attention to detail and problem-solving skills were remarkable. Abhishektank129 not only understood my project requirements but also provided valuable insights and suggestions that greatly enhanced the website's functionality and user experience.",
  },
  {
    id: 14,
    imageUrl: "assets/images/testimonial/phonebook700.png",
    title: "@phonebook700",
    rating: 5,
    description: "BEST of the BEST . very polite and execptional. Finding such people in fiverr is very dificuly. He has mastered Node. It was a risky and complicated task but he cracked the issue in minutes. Saves lots of your time..",
  },
  {
    id: 15,
    imageUrl: "assets/images/testimonial/mr_joe24.png",
    title: "@mr_joe24",
    rating: 5,
    description: "Working with this freelancer was a pleasure! They delivered comprehensive documentation that showcasing understanding of our project requirements.",
  },
  {
    id: 16,
    imageUrl: "assets/images/testimonial/bakr_7272.png",
    title: "@bakr_7272",
    rating: 5,
    description: "I gave him a week to do it and he finished it in a day perfectly. Responds fast and helps with anything.",
  },
  {
    id: 17,
    imageUrl: "assets/images/testimonial/aroset32.png",
    title: "@aroset32",
    rating: 5,
    description: "Works fast and efficiently. Communicates well (which is necessary for someone like me who is new to this process) Also goes above and beyond. Can’t wait to keep working together.",
  },
  {
    id: 18,
    imageUrl: "assets/images/testimonial/blakester555.png",
    title: "@blakester555",
    rating: 5,
    description: "Abhishek was a great provider for this project! He exceeded expectations and delivered more than I asked. Great communicator and very responsive. I would absolutely recommend him for your programming, and integration needs!.",
  },
  {
    id: 19,
    imageUrl: "assets/images/testimonial/johann_voigt.png",
    title: "@johann_voigt",
    rating: 5,
    description: "I really appreciate the quality work and quick results. Absolutely recommend his work.",
  },
  {
    id: 20,
    imageUrl: "assets/images/testimonial/senor_kyle.png",
    title: "@senor_kyle",
    rating: 5,
    description: "Very helpful and willing to go the extra mile. Since I've worked with Abhishek I've learn so much about backend development. Very skilled and gifted at what he does.",
  },
];

const MySlider = () => {
  useEffect(() => {
    feather.replace();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    prevArrow: (
      <button className="slide-arrow prev-arrow">
        <i className="feather-arrow-left"></i>
      </button>
    ),
    nextArrow: (
      <button className="slide-arrow next-arrow">
        <i className="feather-arrow-right"></i>
      </button>
    ),
  };

  return (
    <Slider {...settings}>
      {testimonialData.map((testimonial) => (
        <div
          className="testimonial mt--50 mt_md--40 mt_sm--40"
          key={testimonial.id}
        >
          <div
            className="inner"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <div className="card-info">
              <div className="card-thumbnail">
                <img src={testimonial.imageUrl} alt="Testimonial-image" />
              </div>
              <div className="card-content">
                <span className="subtitle mt--10">{testimonial.subtitle}</span>
                <h3 className="title">{testimonial.title}</h3>
                <span className="designation">{testimonial.designation}</span>
              </div>
            </div> */}
            <div className="card-description">
              <div className="title-area">
                <div className="title-info test-extra">
                  {/* <h3 className="title">{testimonial.project}</h3> */}
                  {/* <span className="date">{testimonial.date}</span> */}
                  <img src={testimonial.imageUrl} alt="Testimonial-image" className="test-img" />
                  <p className="test-p">{testimonial.title}</p>
                </div>
                <div className="rating">
                  {Array.from({ length: testimonial.rating }, (_, index) => (
                    // <img
                    //   key={index}
                    //   src="assets/images/icons/rating.png"
                    //   alt="rating-image"
                    //   className="rating-img"
                    // />
                    <i
                      key={index}
                      className="fas fa-star"
                      style={{ fontSize: "15px", color: "#FFCE38" }}
                    ></i>
                  ))}
                </div>
              </div>
              <div className="seperator"></div>
              <p className="discription">{testimonial.description}</p>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

const Testimonia = () => {
  // useEffect(() => {
  //   feather.replace();
  // }, []);

  return (
    <div
      className="rn-testimonial-area rn-section-gap section-separator"
      id="testimonial"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-delay="100"
              data-aos-once="true"
              className="section-title text-center"
            >
              <span className="subtitle">What Clients Say</span>
              <h2 className="title">Testimonial</h2>
            </div>
          </div>
        </div>
        <div
          className="row"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="300"
          data-aos-once="true"
        >
          <div className="col-lg-12">
            <div className="testimonial-activation testimonial-pb mb--30">
              {/* <!-- Start Single testiminail --> */}
              <MySlider />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonia;
