import React, { useState } from "react";
const Header = ({ setDarkMode, darkMode }) => {
  // const [darkMode, setDarkMode] = useState(false);
  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    document.body.classList.toggle("white-version");
  };

  return (
    <>
      <header class="rn-header haeder-default black-logo-version header--fixed header--sticky">
        <div class="header-wrapper rn-popup-mobile-menu m--0 row align-items-center">
          <div
            class="col-lg-2 col-6 header-left-extra"
            style={{ width: "40%" }}
          >
            <div class="header-left">
              <div
                class="logo"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  columnGap: "10px",
                }}
              >
                <a href="/">
                  <img src="assets/images/logo/logos-circle.png" alt="logo" />
                </a>
                <p className="header-name">
                  <strong>Abhishek</strong> Tank
                </p>
                {/* <span>Tank</span> */}
              </div>
            </div>
          </div>
          <div
            class="col-lg-10 col-6 header-right-extra"
            style={{ width: "60%" }}
          >
            <div class="header-center">
              <nav
                id="sideNav"
                class="mainmenu-nav navbar-example2 d-none d-xl-block onepagenav"
              >
                <ul class="primary-menu nav nav-pills">
                  <li class="nav-item current">
                    <a class="nav-link" href="#home">
                      Home
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#features">
                      Features
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#portfolio">
                      Portfolio
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#skills">
                      Tech Stack
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#testimonial">
                      Testimonial
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#gigs">
                      Gigs
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#contacts">
                      Contact
                    </a>
                  </li>
                </ul>
              </nav>
              <div class="header-right">
                {/* <a
                  class="rn-btn"
                  target="_blank"
                  href="https://themeforest.net/checkout/from_item/33188244?license=regular"
                >
                  <span>BUY NOW</span>
                </a> */}
                <button
                  style={{ background: "none", border: "none" }}
                  className="toggle-btn"
                  onClick={toggleDarkMode}
                >
                  <i
                    className={darkMode ? "feather-sun" : "feather-moon"}
                    style={{ fontSize: "15px" }}
                  ></i>
                </button>

                <div class="hamberger-menu d-block d-xl-none">
                  <i id="menuBtn" class="feather-menu humberger-menu"></i>
                </div>
                <div class="close-menu d-block">
                  <span class="closeTrigger">
                    <i data-feather="x"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div class="popup-mobile-menu">
        <div class="inner">
          <div class="menu-top">
            <div class="menu-header">
              <a class="logo" href="/">
                <img
                  src="assets/images/logo/logos-circle.png"
                  alt="Personal Portfolio"
                />
                <p className="header-name" style={{ marginTop: "20px" }}>
                  <strong>Abhishek</strong> Tank
                </p>
              </a>
              <div class="close-button">
                <button class="close-menu-activation close">
                  <i data-feather="x"></i>
                </button>
              </div>
            </div>
            {/* <p class="discription">
              Inbio is a personal portfolio template. You can customize all.
            </p> */}
          </div>
          <div class="content">
            <ul class="primary-menu nav nav-pills onepagenav">
              <li class="nav-item current">
                <a class="nav-link smoth-animation active" href="#home">
                  Home
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link smoth-animation" href="#features">
                  Features
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link smoth-animation" href="#portfolio">
                  Portfolio
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link smoth-animation" href="#skills">
                  Tech Stack
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link smoth-animation" href="#testimonial">
                  Testimonial
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link smoth-animation" href="#gigs">
                  Gigs
                </a>
              </li>

              <li class="nav-item">
                <a class="nav-link smoth-animation" href="#contacts">
                  Contact
                </a>
              </li>
            </ul>

            {/* <div class="social-share-style-1 mt--40">
              <span class="title">find with me</span>
              <ul class="social-share d-flex liststyle">
                <li class="facebook">
                  <a href="#">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-facebook"
                    >
                      <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                    </svg>
                  </a>
                </li>
                <li class="instagram">
                  <a href="#">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-instagram"
                    >
                      <rect
                        x="2"
                        y="2"
                        width="20"
                        height="20"
                        rx="5"
                        ry="5"
                      ></rect>
                      <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                      <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                    </svg>
                  </a>
                </li>
                <li class="linkedin">
                  <a href="#">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-linkedin"
                    >
                      <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                      <rect x="2" y="9" width="4" height="12"></rect>
                      <circle cx="4" cy="4" r="2"></circle>
                    </svg>
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
